import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, LoadScript, TrafficLayer } from '@react-google-maps/api';
import axios from 'axios';
import mapStyles from '../../assets/estilos/mapStyles';

const MostrarMapa = () => {
  const markerRefs = useRef({}); // Referencia para manejar los marcadores directamente
  const mapRef = useRef(null); // Referencia al mapa
  const [mapLoaded, setMapLoaded] = useState(false); // Estado para controlar si el mapa está listo

  const fetchData = async () => {
    try {
      const empresa_idEmpresa = 1; // 🔹 Ajusta esto según corresponda
      const response = await axios.post('https://cronos.edgarleal.es/token/api/web/Mapa/Obtener_Vehiculos.php', {
        empresa_idEmpresa: empresa_idEmpresa,
      });

      console.log('Datos recibidos:', response.data);

      if (!mapRef.current) {
        console.error('El mapa aún no está listo.');
        return;
      }

      const { google } = window;
      if (!google || !google.maps) {
        console.error('El objeto google no está disponible.');
        return;
      }

      response.data.forEach((item) => {
        if (!item.latitude || !item.longitude) {
          console.error(`Datos inválidos para el vehículo con IMEI ${item.imei}:`, item);
          return;
        }

        const markerId = item.imei;

        if (markerRefs.current[markerId]) {
          // Actualizar posición del marcador existente
          markerRefs.current[markerId].setPosition({
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          });
          console.log(`Marcador actualizado para IMEI ${markerId}`);
        } else {
          // Crear nuevo marcador con la imagen personalizada
          markerRefs.current[markerId] = new google.maps.Marker({
            position: {
              lat: parseFloat(item.latitude),
              lng: parseFloat(item.longitude),
            },
            map: mapRef.current,
            title: `${item.modelo} - ${item.patente}`,
            icon: {
              url: item.imagen, // 📌 Usa la imagen de la API como ícono
              scaledSize: new google.maps.Size(50, 50), // Ajusta el tamaño del icono
            },
          });

          console.log(`Marcador creado para ${item.modelo} (${item.patente})`);
        }
      });

    } catch (error) {
      console.error('Error al obtener los datos de la API:', error);
    }
  };


  useEffect(() => {
    let interval;
    if (mapLoaded) {
      fetchData(); // Llama a la función una vez cuando el mapa está cargado
      interval = setInterval(fetchData, 2000); // Configura el intervalo de actualización
    }
    return () => clearInterval(interval); // Limpia el intervalo al desmontar el componente
  }, [mapLoaded]); // Solo ejecuta cuando el mapa está completamente cargado

  const mapTamano = {
    height: '100vh',
    width: '100%',
  };

  const mapOptions = {
    streetViewControl: false,
    styles: [
      ...mapStyles,
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };

  const defaultCenter = { lat: -33.4489, lng: -70.6693 }; // Centro inicial del mapa

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyAvZVnUS5Acu-vBJPXqak-ET6o7watzIbc"
      loadingElement={<div>Cargando el mapa...</div>}
    >
      <GoogleMap
        mapContainerStyle={mapTamano}
        zoom={11}
        center={defaultCenter}
        options={mapOptions}
        onLoad={(map) => {
          mapRef.current = map; // Almacena la referencia al mapa
          setMapLoaded(true); // Marca el mapa como cargado
        }}
      >
        <TrafficLayer autoUpdate />
      </GoogleMap>
    </LoadScript>
  );
};

export default MostrarMapa;
