import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import MapIcon from '@mui/icons-material/Map';
import DirectionsIcon from '@mui/icons-material/Directions';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { Link, useLocation } from 'react-router-dom'; // Importamos useLocation
import logo from '../../assets/images/logo.png';

const Sidebar = () => {
  const [open, setOpen] = useState(true); // Estado para abrir/cerrar el Sidebar
  const location = useLocation(); // Obtenemos la ubicación actual con useLocation

  const toggleSidebar = () => {
    setOpen(!open);
  };

  // Función para obtener los estilos según la ruta activa
  const getButtonStyle = (path) => {
    const isSelected = location.pathname === `/${path}`;
    return {
      borderRadius: "10px",
      bgcolor: isSelected ? "#d32f2f" : "transparent", // Mantener el color rojo cuando está seleccionado
      color: isSelected ? "white" : "black", // Cambiar el texto a blanco cuando está seleccionado
      "&:hover": {
        bgcolor: isSelected ? "#d32f2f" : "#e0e0e0", // Mantener el color rojo en hover si está seleccionado
        color: "white", // Cambiar el texto a blanco en hover
      }
    };
  };


  return (
    <Drawer
      variant="permanent"
      sx={{
        width: open ? 300 : 110, // Tamaño del sidebar
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: open ? 300 : 110,
          overflowX: "hidden",
          transition: "width 0.3s ease-in-out", // Animación suave
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "white", // Color del fondo
          borderRight: "1px solid #e0e0e0",
        },
      }}
      open={open}
    >
      {/* Botón dentro del Sidebar */}
      <IconButton onClick={toggleSidebar} sx={{ alignSelf: "flex-end", marginBottom: 2 }}>
        <MenuIcon />
      </IconButton>

      {/* Logo */}
      <Box textAlign="center" mb={2}>
        <img src={logo} alt="Logo" width={open ? 120 : 50} />
      </Box>

      {/* Menú */}
      <Paper elevation={0} sx={{ flexGrow: 1, width: "100%", padding: 2 }}>
        <List>
          <ListItem disableGutters disablePadding>
            <ListItemButton component={Link} to="/inicio" sx={getButtonStyle("inicio")}>
              <ListItemIcon sx={{ color: location.pathname === "/inicio" ? "white" : "black" }}>
                <DashboardCustomizeOutlinedIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Inicio" />}
            </ListItemButton>
          </ListItem>
        </List>

        {/* Sección Servicios */}
        <Typography fontWeight={600} mt={2} color="gray" sx={{ display: open ? "block" : "none" }}>
          Servicios
        </Typography>
        <List>
          <ListItem disableGutters disablePadding>
            <ListItemButton component={Link} to="/rutas" sx={getButtonStyle("rutas")}>
              <ListItemIcon>
                <DirectionsIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Rutas" />}
            </ListItemButton>
          </ListItem>

          <ListItem disableGutters disablePadding>
            <ListItemButton component={Link} to="/mapa" sx={getButtonStyle("mapa")}>
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Mapa" />}
            </ListItemButton>
          </ListItem>
        </List>

        {/* Sección Investments */}
        <Typography fontWeight={600} mt={2} color="gray" sx={{ display: open ? "block" : "none" }}>
          Investments
        </Typography>
        <List>
          <ListItem disableGutters disablePadding>
            <ListItemButton component={Link} to="/usuarios" sx={getButtonStyle("usuarios")}>
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Usuarios" />}
            </ListItemButton>
          </ListItem>

          <ListItem disableGutters disablePadding>
            <ListItemButton component={Link} to="/destinos" sx={getButtonStyle("destinos")}>
              <ListItemIcon>
                <EditLocationIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Destinos" />}
            </ListItemButton>
          </ListItem>

          <ListItem disableGutters disablePadding>
            <ListItemButton component={Link} to="/configuracion" sx={getButtonStyle("configuracion")}>
              <ListItemIcon>
                <SettingsApplicationsIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Configuración" />}
            </ListItemButton>
          </ListItem>
        </List>
      </Paper>
    </Drawer>
  );
};

export default Sidebar;
