import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Card } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import CardUsuarios from '../components/mapa/CardUsuarios';
import MostrarMapa from '../components/mapa/MostrarMapa'; // Importa tu componente MostrarMapa

const defaultCenter = { lat: -33.4489, lng: -70.6693 }; // Centro por defecto del mapa

const MapaPage = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [marcadores, setMarcadores] = useState([]);
    const [rutas, setRutas] = useState([]);

    useEffect(() => {
        setMarcadores(
            usuarios.map(usuario => ({
                id: usuario.id,
                position: { lat: parseFloat(usuario.latitude), lng: parseFloat(usuario.longitude) },
                title: `Usuario: ${usuario.usuario}`,
                photoURL: usuario.fotoperfil
            }))
        );
    }, [usuarios]);

    return (
        <Container fluid className="mt-3">
            <Row className="g-3">
                {/* Mapa (ocupa 8 columnas en escritorio, 12 en móvil) */}
                <Col xs={12} md={9}>
                    <MostrarMapa />
                </Col>

                {/* Tarjetas de usuarios (ocupa 4 columnas en escritorio, 12 en móvil) */}
                <Col xs={12} md={3} style={{ overflowY: 'auto', padding: '10px' }}>
                    {usuarios.map(usuario => (
                        <CardUsuarios key={usuario.id} usuario={usuario} />
                    ))}
                </Col>
            </Row>
        </Container>
    );
};

export default MapaPage;
